<template>
	<div id="team_user_tips">
		<el-dialog :title="pageConf.title" v-model="pageConf.show" destroy-on-close :width="1200">
				<CusTable ref="cusTable" title="团队管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
					:cusColums="tableInfo.columns" @chosePage="tableChosePage" :ispaging="true"></CusTable>
			
		</el-dialog>
	</div>
</template>

<script>
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		onMounted,
		watch
	} from 'vue'
	import CusTable from '@/components/common/CusTable.vue'
	export default defineComponent({
		name: 'team_user_tips',
		components: {
			CusTable
		},
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties
			const tableInfo = reactive({
				list: [],
				conf: {
					loadStatus: false,
					curPage: 1,
					pageLimit: 10,
					dataTotal: 0,
					sigleHide: false,
				},
				columns: [{
					"label": "任务ID",
					"prop": "ytl_ytnid",
					"width": 150,
					"show": true
				}, {
					"label": "任务名称",
					"prop": "ytn_title",
					"width": 200,
					"show": true
				}, {
					"label": "子任务ID",
					"prop": "ytl_num",
					"width": 100,
					"show": true
				}, {
					"label": "子任务名称",
					"prop": "ytl_title",
					"width": 200,
					"show": true
				}, {
					"label": "提前/超时天数",
					"prop": "complete_day",
					"width": 140,
					"show": true,
					"color":"complete_status_color"
				}, {
					"label": "预计完成时间",
					"prop": "ytl_plan_time",
					"width": 140,
					"show": true
				}, {
					"label": "实际完成时间",
					"prop": "ytl_suc_time",
					"width": 140,
					"show": true
				}]
			})
			// 弹出层配置文件
			const pageConf = reactive({
				title: '',
				show: false,

				submit_btn_isload: false
			})

			// 成员任务列表
			const taskInfo = reactive({
				u_id: '',
				type: '',
				starttime:'',
				endtime:'',
				page:1,
				limit:10,
				list: [],
				sum: {
					task: '',
					tips: ''
				}
			})

			// 显示页面
			const openPage = (u_id, title, type,starttime,endtime) => {
				// 显示页面
				pageConf.show = true

				// // 存放弹窗标题
				if (type === 1) {
					pageConf.title = title + '-延迟完成任务'
				} else if (type === 2) {
					pageConf.title = title + '-按时完成任务'
				} else if (type === 3) {
					pageConf.title = title + '-提前完成任务'
				}
				else if (type === 4) {
					pageConf.title = title + '-所有任务'
				}

				// // 存放用户ID
				taskInfo.u_id = u_id

				// // 存放查询类型
				taskInfo.type = type
				taskInfo.starttime = starttime
				taskInfo.endtime = endtime

				// // 获取当前成员参与的任务信息
				getTaskList()
			}

			// 获取当前成员参与的任务信息
			const getTaskList = () => {
				// 执行请求操作
				ctx.$request_.post(ctx.$api_.state.Task.TaskStatistics.user_task_list.url, {
						u_id: taskInfo.u_id,
						type: taskInfo.type,
						starttime:taskInfo.starttime,
						endtime:taskInfo.endtime,
						page:tableInfo.conf.curPage,
						limit:tableInfo.conf.pageLimit,
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 加载子任务信息
							tableInfo.list = respon.Data.data
							tableInfo.conf.dataTotal = respon.Data.total
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}
			
			// 设置table当前页码变化
			const tableChosePage = (curPage) => {
				// 存放当前页面
				tableInfo.conf.curPage = curPage
				// 重新加载table数据
				getTaskList()
			}
			
			return {
				pageConf,
				taskInfo,
				openPage,
				tableInfo,
				tableChosePage
			}
		}
	})
</script>

<style lang="less">
	#team_user_tips {
		.user_tips-ul {
			list-style: none;
			padding: 0;
			max-height: 60vh;
			overflow-y: auto;

			.user_tips-col {
				margin-bottom: 4vh;

				.main-task {
					.title_id {
						display: inline-block;
						background-color: #409eff;
						color: #ffffff;
						text-align: center;
						min-width: 0.6vw;
						height: 0.6vw;
						line-height: 0.8vw;
						font-size: 0.6vw;
						padding: 0.2vw;
						border-radius: 0.1vw;
					}

					.title_name {
						height: 0.8vw;
						line-height: 0.8vw;
						margin-left: 0.3vw;
						font-size: 0.8vw;
						color: #333333;
					}

					.task_time {
						float: right;
						margin-right: 0.6vw;
						color: #999999;
					}
				}
			}

			.user_tips-row {
				background-color: #f7f8fa;
				margin: 1vh 0 0 0 !important;
				height: 4vw;
				line-height: 1.2vw;
				display: flex;
				align-items: center;
				color: #999999;

				.list-content-right {
					text-align: right;
				}
			}
		}

		.dialog-footer {
			text-align: left;

			.footer-title {
				color: #999999;
				font-size: 0.9vw;

				.footer-num {
					color: #409EFF;
					font-weight: 600;
					margin-left: 0.2vw;
					font-size: 1vw;
				}
			}

			.footer-title:last-child {
				margin-left: 1.5vw;
			}
		}
	}
</style>
