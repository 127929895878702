<template>
	<div id="task_team">
		<el-row>
			<el-col :span="4">
				<el-select v-model="tableInfo.conf.team_id" clearable filterable placeholder="全部团队"
					@change="searchGroupUser(1)">
					<el-option v-for="item in teamList.options" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="4">
				<el-date-picker v-model="tableInfo.conf.times" type="daterange" range-separator="-"
					start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTimes" />
			</el-col>
			<el-col :span="6" :offset="10">
				<el-input placeholder="请输入成员姓名、英文简称、手机号码" clearable v-model="tableInfo.conf.keywords"
					@keyup.enter="searchGroupUser(2)" @clear="searchGroupUser(2)">
					<template #append>
						<i class="iconfont icon-edit" @click="searchGroupUser(2)">&#xe6ab;</i>
					</template>
				</el-input>
			</el-col>
		</el-row>

		<div class="table">
			<CusTable ref="cusTable" title="团队管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
				:cusColums="tableInfo.columns" @showOntimeNum="showOntimeNum" @showLeadingNum="showLeadingNum"
				@showOvertimeNum="showOvertimeNum" @showAllNum="showAllNum" :ispaging="true"></CusTable>
		</div>
		<div class="dialog-page">
			<!-- tips情况 start -->
			<TaskToNumDetail ref="taskToNumDetail"></TaskToNumDetail>
			<!-- tips情况 end -->
		</div>
	</div>
</template>

<script type="text/javascript">
	import tableColumns from '@/assets/json/task/table/task-statistics.json'
	import CusTable from '@/components/common/CusTable.vue'
	import TaskToNumDetail from '@/components/task/TaskToNumDetail.vue'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'task_team',
		components: {
			CusTable,
			TaskToNumDetail
		},
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 弹出层配置文件
			const pageConf = reactive({
				insert: false,
				edit: false,
				search_user_list: [],
				search_user_chose: [],
				select_check_uid: '',
				select_isload: false,
				submit_btn_isload: false
			})

			// 表单-挂载
			const formRef = ref()

			// 团队列表
			const teamList = reactive({
				options: []
			})

			// 获取团队信息
			const getTeam = () => {
				// 执行请求命令
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.team_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							teamList.options = respon.Data
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 顶部搜索栏
			const searchGroupUser = (type) => {
				if (type === 1) {
					//select筛选，清空关键字查询
					tableInfo.conf.keywords = ''
				} else if (type === 2) {
					//关键字查询，清空select筛选
					tableInfo.conf.team_id = ''
					tableInfo.conf.duty_id = ''
				}

				// 重置分页参数
				tableInfo.conf.curPage = 1

				// 执行搜索请求
				loadTeamList()
			}




			/*团队成员列表*/
			const tableInfo = reactive({
				list: [],
				columns: ctx.$pubFun_.verify_tableColumns(tableColumns.list, 'Team'),
				conf: {
					loadStatus: false,
					curPage: 1,
					pageLimit: 100,
					dataTotal: 0,
					sigleHide: true,
					keywords: '',
					team_id: '',
					duty_id: '',
					emptyText: '',
					times: ''
				}
			})

			// 获取团队成员列表
			const loadTeamList = () => {
				// 重置参数
				tableInfo.list = []
				tableInfo.conf.loadStatus = true

				// 执行请求操作
				ctx.$request_nl_.post(ctx.$api_.state.Task.TaskStatistics.user_task_statistics.url, {
						page: tableInfo.conf.curPage,
						limit: tableInfo.conf.pageLimit,
						team_id: tableInfo.conf.team_id,
						starttime: tableInfo.conf.times ? new Date(tableInfo.conf.times[0]).getTime() /
							1000 : '',
						endtime: tableInfo.conf.times ? new Date(tableInfo.conf.times[1]).getTime() /
							1000 : '',
						keywords: tableInfo.conf.keywords
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 循环存放团队数据
							tableInfo.list = respon.Data
							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = '没有更多的数据了~'
						} else {
							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = respon.Message
						}
					}).catch(error => {
						tableInfo.conf.loadStatus = false
						tableInfo.conf.emptyText = "服务器连接失败，请稍后重试"
					})
			}

			/*团队成员列表*/


			// 团队成员tips挂载
			const taskToNumDetail = ref(null)

			// 按时完成任务
			const showOntimeNum = (index,row) => {
				taskToNumDetail.value.openPage(row.u_id, row.u_name, 2,
					tableInfo.conf.times ? new Date(tableInfo.conf.times[0]).getTime() / 1000 : '',
					tableInfo.conf.times ? new Date(tableInfo.conf.times[1]).getTime() / 1000 : '')
			}
			//提前完成任务
			const showLeadingNum = (index,row) => {
				taskToNumDetail.value.openPage(row.u_id, row.u_name, 3,
					tableInfo.conf.times ? new Date(tableInfo.conf.times[0]).getTime() / 1000 : '',
					tableInfo.conf.times ? new Date(tableInfo.conf.times[1]).getTime() / 1000 : '')
			}
			//延迟完成任务
			const showOvertimeNum = (index,row) => {
				taskToNumDetail.value.openPage(row.u_id, row.u_name, 1,
					tableInfo.conf.times ? new Date(tableInfo.conf.times[0]).getTime() / 1000 : '',
					tableInfo.conf.times ? new Date(tableInfo.conf.times[1]).getTime() / 1000 : '')
			}
			//所有任务
			const showAllNum = (index,row) => {
				taskToNumDetail.value.openPage(row.u_id, row.u_name, 4,
					tableInfo.conf.times ? new Date(tableInfo.conf.times[0]).getTime() / 1000 : '',
					tableInfo.conf.times ? new Date(tableInfo.conf.times[1]).getTime() / 1000 : '')
			}

			/*初次自动加载*/
			getTeam()
			loadTeamList()
			/*初次自动加载*/

			//选择时间范围
			const changeTimes = (e) => {
				loadTeamList()
			}
			return {
				changeTimes,
				pageConf,
				formRef,
				teamList,
				searchGroupUser,
				tableInfo,
				taskToNumDetail,
				showOntimeNum,
				showLeadingNum,
				showOvertimeNum,
				showAllNum
			}
		}
	})
</script>

<style lang="less">
	#task_team {
		.insert {
			margin-top: 1vw;
		}

		.el-dialog {
			min-height: 15vw;

			.el-form {
				text-align: center;

				.el-form-item:last-child {
					margin-bottom: 0;
				}

				.form-select {
					width: 100%;
				}

				.form-input {
					width: 100%;
					margin-top: 1vw;
				}

				.sub-title {
					text-align: left;
				}

				.user-list {
					max-height: 16vw;
					overflow-y: auto;
					overflow-x: hidden;

					.user-row {
						background-color: #f5f6f7;

						.set_heademan,
						.del_user {
							cursor: pointer;
						}
					}

					.user-row:not(:first-child) {
						margin-top: 0.3vw;
					}
				}
			}
		}
	}
</style>
